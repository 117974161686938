import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper, Row,
} from "@tanstack/react-table";
import {
  Center,
  Flex, Image,
  Input,
  Link,
  Modal, ModalBody,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import styles from "../lib/input/input.module.scss";
import tableStyles from "../lib/table/table.module.scss";
import calendar from "../../images/svg/calendar/calendar.svg";
import { ChakraModalProps } from "../../shared/types/modalProps";
import MainLogo from "../../images/svg/main-logo/main-logo.svg";
import { BlockTitle, Title } from "../lib/title/Title";
import { PrimaryButton } from "../lib/button/Button";
import SuccessLogo from "../../images/png/success.png";
import { changeCurrentLimitFenceCard } from "../../store/slices/limitFenceCards";
import close from "../../images/svg/close/close.svg";
import { formatAmount } from "../../helpers/formatAmount";
import dayjs from "dayjs";
import { IContractMaterial } from "../../api/contracts/types";
import { changeContractMaterials } from "../../store/slices/contracts";
import { CloseIcon } from "@chakra-ui/icons";

interface IMaterialsTableProps {
  searchValue?: string;
  setMaterialRow?: (material: IContractMaterial | undefined, index: number | undefined) => void;
  setDisableApprove?: (arg: boolean) => void;
  disabledChange?: boolean;
  isCreate?: boolean
  purchaseRequest?: boolean
}

interface IEditModal extends ChakraModalProps {
  row?: Row<IContractMaterial>;
}

const columnHelper = createColumnHelper<IContractMaterial>();

const MeasureCell = (info: CellContext<IContractMaterial, string>) => (
  <Center>{info.renderValue()}</Center>
);

const CenterCell = (text: string) => <Center w="full">{text}</Center>;

const AmountCell = (info: CellContext<IContractMaterial, string>, setDisable: (arg: boolean) => void, setMaterialRow?: (material: IContractMaterial | undefined, index: number | undefined) => void, disabledChange?: boolean) => {
  const { row } = info;
  const dispatch = useAppDispatch();
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);
  const [value, setValue] = useState(info.renderValue() ? String(Number(info.renderValue()).toFixed(3)) : "");

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    formatValue = formatValue === "" ? "0" : formatValue;
    formatValue = parseFloat(formatValue).toFixed(3);
    setValue(formatValue);

    const rowId = row.original.table_guid;
    if (rowId !== undefined && currentContractMaterials) {
      const updatedMaterials: IContractMaterial[] = currentContractMaterials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            amount: formatValue,
          };
        }
        return material;
      });

      dispatch(changeContractMaterials(updatedMaterials));
    }
  }

  useEffect(() => {
    if (currentContractMaterials) {
      const hasChanges = currentContractMaterials.some(
        (material, index) => material.amount !== info.row.original.amount && index === info.row.index
      );
      if (hasChanges) {
        setDisable(true);
      }
    }
  }, [currentContractMaterials, info.row.original.amount, info.row.index, setDisable]);

  useEffect(() => {
    setValue(row.original.amount ? String(Number(row.original.amount).toFixed(3)) : "")
  }, [row.original.amount]);

  return (
    <>
      {!disabledChange ? (
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={`${styles.input_container} ${styles.table_input}`}>
              <input
                value={value}
                id={info.row.original.material_guid}
                onChange={(e) =>
                  setValue(e.target.value.replace(/[^0-9.]/g, ""))
                }
                onBlur={handleBlur}
                onKeyDown={(e) =>
                  e.key === "Enter" && (e.target as HTMLInputElement).blur()
                }
                onClick={(e) => e.stopPropagation()}
                disabled={disabledChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <Text>{info.renderValue()}</Text>
      )}
    </>
  );
};

const PeriodCell = (info: CellContext<IContractMaterial, string>, disabledChange?: boolean) => {
  const inputStyle = `url(${calendar}) no-repeat 100% 50% content-box`;
  const isDisabled = Number(info.row.original.amount) === 0 || info.row.original.amount === undefined || info.row.original.amount === "";
  const dispatch = useAppDispatch();
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);
  const { completedMaterials } = useAppSelector((state) => state.limitFenceCards);
  const [value, setValue] = useState(isDisabled ? "" : info.row.original.period);

  useEffect(() => {
    setValue(isDisabled ? '' : info.row.original.period);
  }, [info.row.original.period, isDisabled]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentContractMaterials) {
      const updatedMaterials: IContractMaterial[] = currentContractMaterials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            period: e.target.value,
          };
        }
        return material;
      });

      dispatch(changeContractMaterials(updatedMaterials));
    }
  }

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === 'c' || event.key === 'с') {
        event.preventDefault();
        navigator.clipboard.writeText(`${value}`)
      }
    }

    if (event.metaKey || event.ctrlKey) {
      if (event.key === 'v' || event.key === 'м') {
        event.preventDefault();
        navigator.clipboard.readText()
        .then((clipText: string) => {
          setValue(clipText)
        })
      }
    }
  }

  return (
    !disabledChange ? (
      <Input
        h="36px"
        rounded="md"
        type="date"
        borderColor="#E3E7EF"
        id={info.row.original.guid}
        value={value}
        onChange={onDateChange}
        onBlur={handleBlur}
        className={tableStyles.input_date}
        onClick={(e) => {
          e.stopPropagation()
        }}
        disabled={isDisabled}
        onKeyDown={handleKeyDown}
      />
    ) : (
      <Text>{dayjs(info.renderValue()).format("DD.MM.YYYY")}</Text>
    )
  )
}

const CommentCell = (info: CellContext<IContractMaterial, string>, onOpenCommentForm: (e: React.MouseEvent, row: Row<IContractMaterial>) => void, disabledChange?: boolean) => {
  const isDisabled = Number(info.row.original.amount) === 0 || info.row.original.amount === undefined || info.row.original.amount === "";

  return (
    disabledChange ? (
      <Text noOfLines={2}>{info.renderValue()}</Text>
    ) : (
      info.renderValue() ? (
        <Text noOfLines={2} onClick={(e: React.MouseEvent) => onOpenCommentForm(e, info.row)}>{info.renderValue()}</Text>
      ) : (
        <Center color={isDisabled ? "#AAB0C1" : "#2AB6A5"} cursor={isDisabled ? "auto" : "pointer"} onClick={(e: React.MouseEvent) => !isDisabled && onOpenCommentForm(e, info.row)} >Добавить</Center>
      )
    )
  );
}

const DeleteCell = (info: CellContext<IContractMaterial, string>) => {
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const handleDelete = () => {
    const changedMaterials = currentContractMaterials.filter((el) => el.material_guid !== info.row.original.material_guid)
    dispatch(changeContractMaterials(changedMaterials));
  }

  return (
    <>
      {info.row.original.isNewItem && (
        <Flex w="30px" justifyContent="center">
          <CloseIcon color="#8089A2" sx={{
            "&:hover": {
              opacity: 0.6
            }
          }} onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            handleDelete();
          }} />
        </Flex>
      )}
    </>
  )
}

const CustomNameCell = (info: CellContext<IContractMaterial, string>) => {
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const isEdited = info.row.original.isNewItem;
  const [value, setValue] = useState<string | undefined>(info.renderValue() ?? "")
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setValue(rawValue);

    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentContractMaterials) {
      const updatedMaterials: IContractMaterial[] = currentContractMaterials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            custom_material_name: rawValue,
          };
        }
        return material;
      });

      dispatch(changeContractMaterials(updatedMaterials));
    }
  }

  useEffect(() => {
    setValue(info.row.original.custom_material_name)
  }, [currentContractMaterials, info.row.original.custom_material_name])

  return (
    <>
      {isEdited && (<div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={`${styles.input_container} ${styles.table_input}`}>
            <input
              value={value}
              onChange={(e) =>
                setValue(e.target.value)
              }
              onBlur={handleBlur}
              onKeyDown={(e) =>
                e.key === "Enter" && (e.target as HTMLInputElement).blur()
              }
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>)}

      {!isEdited && info.renderValue()}
    </>
  )
}

const CustomUnitCell = (info: CellContext<IContractMaterial, string>) => {
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const isEdited = info.row.original.isNewItem;
  const [value, setValue] = useState<string | undefined>(info.renderValue() ?? "")
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    setValue(rawValue);

    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentContractMaterials) {
      const updatedMaterials: IContractMaterial[] = currentContractMaterials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            custom_unit_of_measurement: rawValue,
          };
        }
        return material;
      });

      dispatch(changeContractMaterials(updatedMaterials));
    }
  }

  useEffect(() => {
    setValue(info.row.original.custom_unit_of_measurement)
  }, [currentContractMaterials, info.row.original.custom_unit_of_measurement])

  return (
    <>
      {isEdited && (<div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={`${styles.input_container} ${styles.table_input}`}>
            <input
              value={value}
              onChange={(e) =>
                setValue(e.target.value)
              }
              onBlur={handleBlur}
              onKeyDown={(e) =>
                e.key === "Enter" && (e.target as HTMLInputElement).blur()
              }
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
      </div>)}
      {!isEdited && info.renderValue()}
    </>
  )
}

const CustomPriceCell = (info: CellContext<IContractMaterial, string>) => {
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string | undefined>("")
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    let formatValue = rawValue.replace(/[^0-9.]/g, "").replace(/^0+(?!\.)|\.*$/g, "");
    formatValue = formatValue === "" ? "0" : formatValue;
    formatValue = parseFloat(formatValue).toFixed(2);
    setValue(formatValue);

    const rowId = info.row.original.table_guid;
    if (rowId !== undefined && currentContractMaterials) {
      const updatedMaterials: IContractMaterial[] = currentContractMaterials.map((material) => {
        if (material.table_guid === rowId) {
          return {
            ...material,
            tender_price: formatValue,
          };
        }
        return material;
      });

      dispatch(changeContractMaterials(updatedMaterials));
    }
  }

  useEffect(() => {
    setValue(String(Number(info.row.original.tender_price).toFixed(2)))
  }, [currentContractMaterials, info.row.original.tender_price])

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={`${styles.input_container} ${styles.table_input}`}>
          <input
            value={value}
            onChange={(e) =>
              setValue(e.target.value.replace(/[^0-9.]/g, ""))
            }
            onBlur={handleBlur}
            onKeyDown={(e) =>
              e.key === "Enter" && (e.target as HTMLInputElement).blur()
            }
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    </div>
  )
}

const SummCell = (info: CellContext<IContractMaterial, string>) => {
  const amount = Number(info.row.original.amount);
  const tenderPrice = Number(info.row.original.tender_price);

  const value = isNaN(amount) || isNaN(tenderPrice)
    ? '' // Возвращаем пустую строку, если amount или tenderPrice - NaN
    : (amount * tenderPrice).toFixed(2);

  return (
    <Text minW="150px">{formatAmount(value)}</Text>
  );
};


export function CreateMaterialsTable({ searchValue, setMaterialRow, setDisableApprove, disabledChange, purchaseRequest }: IMaterialsTableProps) {
  const dispatch = useAppDispatch();

  const { currentContractMaterials } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const [wrap, setWrap] = useState<boolean>(false);

  const toggleTableWrap = (): void => {
    setWrap(!wrap);
  }


  const [filteredMaterials, setFilteredMaterials] = useState(currentContractMaterials);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [row, setRow] = useState<Row<IContractMaterial>>();

  const onOpenCommentForm = useCallback((e: React.MouseEvent, row: Row<IContractMaterial>) => {
    e.stopPropagation();
    setRow(row);
    onOpen();
  }, [onOpen]);

  const onMaterialTableClick = (row: Row<IContractMaterial>) => {
    row.toggleSelected();
    setMaterialRow && setMaterialRow(row.getIsSelected() ? undefined : row.original, row.getIsSelected() ? undefined : row.index)
  }

  const setDisable = useCallback((arg: boolean) => {
    if (setDisableApprove) {
      setDisableApprove(arg);
    }
  }, [setDisableApprove]);

  useEffect(() => {
    setFilteredMaterials(currentContractMaterials)
  }, [currentContractMaterials])

  useEffect(() => {
    const filterMaterials = (materials: any) => {
      if (searchValue) {
        const searchTerm = searchValue.toLowerCase().trim();
        return materials.filter((item: any) =>
          (item.material_name ?? "").toLowerCase().includes(searchTerm) ||
          (item.custom_material_name ?? "").toLowerCase().includes(searchTerm) ||
          String(item.amount ?? "").toLowerCase().includes(searchTerm) ||
          String(item.ssom_order_number ?? "").toLowerCase().includes(searchTerm) ||
          (item.analog_material ?? "").toLowerCase().includes(searchTerm) ||
          (item.unit_of_measurement ?? "").toLowerCase().includes(searchTerm) ||
          String(item.indicative_price ?? "").toLowerCase().includes(searchTerm) ||
          String(item.tender_quantity ?? "").toLowerCase().includes(searchTerm) ||
          String(item.tender_price ?? "").toLowerCase().includes(searchTerm) ||
          (item.tender_solution ?? "").toLowerCase().includes(searchTerm) ||
          (item.inventory_supervisor ?? "").toLowerCase().includes(searchTerm) ||
          (item.period ?? "").toLowerCase().includes(searchTerm) ||
          (item.comment ?? "").toLowerCase().includes(searchTerm) ||
          (item.custom_unit_of_measurement ?? "").toLowerCase().includes(searchTerm) ||
          String(item.order_number ?? "").toLowerCase().includes(searchTerm)
        );
      } else {
        return materials;
      }
    };

    const filtered = filterMaterials(currentContractMaterials || []);
    setFilteredMaterials(!wrap ? filtered : filtered.filter((item: any) => item.amount !== 0 && item.amount !== null && item.amount !== undefined && item.amount !== ''));
  }, [searchValue, currentContractMaterials, wrap]);


  const columns = useMemo<Array<ColumnDef<IContractMaterial, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          columnHelper.accessor("ssom_order_number", {
            header: "№ ССОМ",
          }),
          columnHelper.accessor("material_name", {
            header: "Номенклатура",
          }),
          columnHelper.accessor("analog_material", {
            header: "Аналог номенклатуры",
          }),
          columnHelper.accessor("unit_of_measurement", {
            header: "Ед. изм.",
            cell: MeasureCell,
          }),
          columnHelper.accessor("indicative_price", {
            header: "Цена индикатива",
            cell: (info) => formatAmount(info.renderValue())
          }),
        ],
      }),

      columnHelper.group({
        id: "tender",
        header: () => CenterCell("Решение по тендеру"),
        columns: [
          columnHelper.accessor("tender_quantity", {
            header: "Количество",
          }),
          columnHelper.accessor("tender_price", {
            header: "Цена",
            cell: (info) => formatAmount(info.renderValue())
          }),
          columnHelper.accessor("tender_solution", {
            header: "Решение по тендеру",
          }),
        ],
      }),
      columnHelper.group({
        id: "volume",
        header: () => CenterCell("Объёмы"),
        columns: [
          columnHelper.accessor("amount", {
            header: "Количество",
            meta: { cellClassName: !disabledChange ? "changeable" : "" },
            cell: (info) => AmountCell(info, setDisable, setMaterialRow, disabledChange),
          }),
          columnHelper.accessor("summ", {
            header: "Сумма",
            cell: (info) => SummCell(info)
          }),
          columnHelper.accessor("period", {
            header: "Период поставки",
            meta: { cellClassName: !disabledChange ? "changeable" : "" },
            cell: (info) => PeriodCell(info, disabledChange),
          }),
          columnHelper.accessor("comment", {
            header: "Комментарий подрядчика",
            cell: (info) => CommentCell(info, onOpenCommentForm, disabledChange),
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
        ],
      }),
      columnHelper.accessor("inventory_supervisor", {
        header: "Куратор ТМЦ",
      }),
    ],
    [onOpenCommentForm, setMaterialRow, setDisable]
  );

  const columnsСreate = useMemo<Array<ColumnDef<IContractMaterial, string>>>(
    () => [
      columnHelper.group({
        id: "info",
        columns: [
          columnHelper.accessor("delete", {
            header: "",
            cell: DeleteCell
          }),
          columnHelper.accessor("ssom_order_number", {
            header: "№ ССОМ",
          }),
          columnHelper.accessor("material_name", {
            header: "Номенклатура",
          }),
          columnHelper.accessor("custom_material_name", {
            header: "Номенклатура строкой",
            cell: CustomNameCell,
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
          columnHelper.accessor("analog_material", {
            header: "Аналог номенклатуры",
          }),
          columnHelper.accessor("unit_of_measurement", {
            header: "Ед. изм.",
            cell: MeasureCell,
          }),
          columnHelper.accessor("custom_unit_of_measurement", {
            header: "Ед. изм. строкой",
            cell: CustomUnitCell,
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
          columnHelper.accessor("indicative_price", {
            header: "Цена индикатива",
            cell: (info) => info.renderValue() !== "" && formatAmount(info.renderValue())
          }),
          columnHelper.accessor("tender_price", {
            header: "Цена",
            cell: CustomPriceCell,
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
        ],
      }),
      columnHelper.group({
        id: "volume",
        header: () => CenterCell("Объёмы"),
        columns: [
          columnHelper.accessor("amount", {
            header: "Количество",
            meta: { cellClassName: !disabledChange ? "changeable" : "" },
            cell: (info) => AmountCell(info, setDisable, setMaterialRow, disabledChange),
          }),
          columnHelper.accessor("summ", {
            header: "Сумма",
            cell: (info) => SummCell(info)
          }),
          columnHelper.accessor("period", {
            header: "Период поставки",
            meta: { cellClassName: !disabledChange ? "changeable" : "" },
            cell: (info) => PeriodCell(info, disabledChange),
          }),
          columnHelper.accessor("comment", {
            header: "Комментарий подрядчика",
            cell: (info) => CommentCell(info, onOpenCommentForm, disabledChange),
            meta: { cellClassName: !disabledChange ? "changeable" : "" }
          }),
        ],
      }),
      columnHelper.accessor("inventory_supervisor", {
        header: "Куратор ТМЦ",
      }),
    ],
    [onOpenCommentForm, setMaterialRow, setDisable, filteredMaterials]
  );

  return (
    <Flex flexDirection="column" gap="24px">
      <Table
        data={filteredMaterials ?? []}
        columns={purchaseRequest ? columnsСreate : columns}
        rowClick={onMaterialTableClick}
        dblClickDisabled={true}
      />
      <Flex color="#2AB6A5" alignSelf="end">
        <Link onClick={toggleTableWrap}>{`${wrap ? "Показать все" : "Скрыть пустые"} строки`}</Link>
      </Flex>
      <EditModal isOpen={isOpen} onClose={onClose} row={row} />
    </Flex>
  );
}

function EditModal(props: IEditModal) {
  const { currentContractMaterials } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { onClose, isOpen, row } = props;

  const [localComment, setLocalComment] = useState(row?.original.comment ? row?.original.comment : "");

  useEffect(() => {
    setLocalComment(row?.original.comment ? row?.original.comment : "");
  }, [row]);

  const dispatch = useAppDispatch();

  const {
    isOpen: isSuccessOpen,
    onOpen: onSuccessOpen,
    onClose: onSuccessClose,
  } = useDisclosure();

  const handleCommentModalClose = () => {
    if (row) {
      const rowId = row.original.table_guid;
      if (rowId !== undefined && currentContractMaterials) {
        const updatedMaterials: IContractMaterial[] = currentContractMaterials.map((material) => {
          if (material.table_guid === rowId) {
            return {
              ...material,
              comment: localComment ?? "",
            };
          }
          return material;
        });

        dispatch(changeContractMaterials(updatedMaterials));
      }

      onClose();
      onSuccessOpen();
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
          <ModalHeader p={0}>
            <Image cursor="pointer" src={close} float="right" onClick={onClose} />
            <Flex direction="column" align="center" gap="24px" pb="8px">
              <Image src={MainLogo} boxSize="64px" alt="Main Logo" />
              <Title textAlign="center">Комментарий</Title>
            </Flex>
          </ModalHeader>
          <ModalBody p={0}>
            <Textarea h="360px" value={localComment} onChange={(e) => { setLocalComment(e.target.value) }} />
          </ModalBody>
          <ModalFooter p={0}>
            <PrimaryButton
              w="full"
              fontSize="16px"
              onClick={handleCommentModalClose}
            >
              Сохранить
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} />
    </>
  );
}

function SuccessModal(props: Readonly<ChakraModalProps>) {
  const { isOpen, onClose } = props
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent maxW="480px" gap="24px" px="24px" py="32px">
        <ModalHeader p={0}>
          <Flex direction="column" align="center" gap="24px">
            <Image src={SuccessLogo} boxSize="100px" alt="Success Logo" />
            <BlockTitle>Комментарий успешно изменён</BlockTitle>
          </Flex>
        </ModalHeader>
        <ModalFooter p={0}>
          <PrimaryButton w="full" onClick={onClose}>
            Закрыть
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
