import { useEffect, useState } from "react";
import {
  useSearchParams,
} from "react-router-dom";
import { RootState, useAppSelector } from "../../store";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Grid,
  GridItem,
  Link,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Icon
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  attachFilesToContract,
  changeCurrentContract,
  fetchSingleContract,
  fetchSingleContractAccessActs,
  fetchSingleContractConstrictionRemarks,
  fetchSingleContractLettersGuarantees,
  fetchSingleContractPayments,
  fetchSingleContractWorkCompletedActs,
} from "../../store/slices/contracts";
import { OutlineButton, PrimaryButton } from "../../components/lib/button/Button";
import { BlockTitle, Title } from "../../components/lib/title/Title";
import { ChakraTabs, ITab } from "../../components/lib/tabs/Tabs";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { SingleContractInformationBlock } from "../../components/SingleContractComponents/SingleContractInformationBlock";
import { RemarksTable } from "../../components/RemarkTable/RemarksTable";
import { EstimateBlock } from "../../components/EstimateBlock/EstimateBlock";
import { ActsBlock } from "../../components/ActsBlock/ActsBlock";
import { ReconciliationActBlock } from "../../components/ReconciliationAct/ReconciliationActBlock";
import { PaymentBlock } from "../../components/Payment/PaymentBlock";
import { IndemnityLetterBlock } from "../../components/IndemnityLetter/IndemnityLetterBlock";
import { WorkingDocumentationModal } from "../../components/WorkingDocumentationModal/WorkingDocumentationModal";
import SingleDocumentPageData from "../../shared/mock/singleDocumentPageData";
import { OnboardingLink } from "../../components/OnboardingLink/OnboardingLink";
import ComponentWithProps from "../../helpers/ComponentWithProps";
import { fetchEstimates, resetCurrentEstimate } from "../../store/slices/estimates";
import { resetCurrenWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { Notice } from "../../components/lib/notice/Notice";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { checkPermission } from "../../helpers/checkPermission";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { IFilesAttachResponse } from "../../api/files/types";
import { resetCurrentLimitFenceCard } from "../../store/slices/limitFenceCards";

enum ParamKeys {
  Remarks = "remarks",
  RelatedDocuments = "related-docs",
}

export function SingleContractPage({ id, dispatch, navigate, goBack }: any) {
  const UpdatedEstimateBlock = ComponentWithProps(EstimateBlock)
  const UpdatedPaymentBlock = ComponentWithProps(PaymentBlock)
  const { currentContract, currentContractConstructionRemarks } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [isCollapsedList, setIsCollapsedList] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenFile, onOpen: onOpenFile, onClose: onCloseFile } = useDisclosure();
  const [files, setFiles] = useState(currentContract?.files);

  const hasContractConstrictionRemarks= () => {return checkPermission(AccessRights.ConstructionControlNote_RW, permissions)}

  useEffect(() => {
    setFiles(currentContract?.files)
  }, [currentContract])

  const relatedDocsTabIndex = Number(
    searchParams.get(ParamKeys.RelatedDocuments)
  );

  const fileAttach = (files: File[]) => {
    if (currentContract) {
      dispatch(attachFilesToContract({id: String(currentContract.id), files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data?.files);
      });
    }
  }

  const setDefaultTabParam =
    (key: ParamKeys) =>
    (index: number): void => {
      index === 0
        ? searchParams.delete(key)
        : searchParams.set(key, String(index));

      setSearchParams(searchParams);
    };

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleContract(Number(id)));
      if(hasContractConstrictionRemarks()){
        dispatch(fetchSingleContractConstrictionRemarks(Number(id)))
      }

    }
    dispatch(resetCurrenWorkCompletedAct())
    dispatch(resetCurrentEstimate())
    dispatch(resetCurrentLimitFenceCard())
  }, [dispatch, id]);

  useEffect(() => {
    switch (filteredTabs[relatedDocsTabIndex].id) {
      case 0:
        dispatch(fetchSingleContractWorkCompletedActs(Number(id)));
        dispatch(fetchEstimates(Number(id)));
        break;
      case 1:
        dispatch(fetchSingleContractAccessActs(Number(id)));
        break;
      case 2:
        dispatch(fetchSingleContractPayments(Number(id)));
        dispatch(changeCurrentContract({...currentContract, counter: {...currentContract?.counter, payments: 0}}))
        break;
      case 3:
        dispatch(fetchSingleContractLettersGuarantees(Number(id)));
        dispatch(changeCurrentContract({...currentContract, counter: {...currentContract?.counter, letter_guarantees: 0}}))
        break;
    }
  }, [relatedDocsTabIndex, dispatch, id])

  const boundDocumentsTabs: ITab[] = [
    { id: 0, label: "Сметы, акты", notice: currentContract?.counter?.work_completed_acts ?? 0, permission: [AccessRights.WorkCompletedAct_RO, AccessRights.WorkCompletedAct_RW],
      element:               
      <TabPanel px={0} pb={0} gap={40}>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} h="456px">
          <GridItem maxH="full" overflow="hidden">
            <UpdatedEstimateBlock />
          </GridItem>
          <GridItem maxH="full" overflow="hidden">
            <ActsBlock />
          </GridItem>
        </Grid>
        <Box>
          <OnboardingByPermission permission={AccessRights.Contract_RO} />
        </Box>
      </TabPanel>
    },
    { id: 1, label: "Акты допуска", notice: currentContract?.counter?.access_acts ?? 0, permission: AccessRights.AccessAct_RW,
      element:
      <TabPanel px={0} pb={0}>
        <ReconciliationActBlock />
        <Box>
          <OnboardingByPermission permission={AccessRights.AccessAct_RW} />
        </Box>
      </TabPanel>
    },
    { id: 2, label: "Оплата", notice: currentContract?.counter?.payments ?? 0, permission: AccessRights.Payment_RW,
      element:               
      <TabPanel px={0} pb={0}>
        <UpdatedPaymentBlock />
        <Box>
          <OnboardingByPermission permission={AccessRights.Payment_RW} />
        </Box>
      </TabPanel>

    },
    { id: 3, label: "Гарантийные письма", notice: currentContract?.counter?.letter_guarantees ?? 0, permission: AccessRights.LetterGuarantee_RW,
      element:
      <TabPanel px={0} pb={0}>
        <IndemnityLetterBlock />
        <Box>
          <OnboardingByPermission permission={AccessRights.LetterGuarantee_RW} />
        </Box>
      </TabPanel>
    },
  ];

  const filteredTabs = boundDocumentsTabs.filter(tab => ((tab.permission && checkPermission(tab.permission, permissions)) || !("permission" in tab)))

  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            {!currentContract?.is_archived && (
              <BreadcrumbItem>
                <BreadcrumbLink href="/my-documents">Мои документы</BreadcrumbLink>
              </BreadcrumbItem>
            )}
            <BreadcrumbItem>
              <BreadcrumbLink href={currentContract?.is_archived ? `/archive` : '/my-documents/contracts'}>
              {currentContract?.is_archived ? "Архив договоров" : "Договоры"}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink
                href={currentContract?.is_archived ? `/archive/${currentContract?.id}` : `/my-documents/contracts/${currentContract?.id}`}
              >
                {currentContract?.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack(currentContract?.is_archived ? "/archive" : "/my-documents/contracts")} />
              <BlockTitle>{currentContract?.name}</BlockTitle>
            </Flex>
            <Flex gap={3}>
              <OutlineButton
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
                gap={2}
                isDisabled={!checkPermission(AccessRights.WorkCompletedAct_RW, permissions)}
                onClick={() => onOpenFile()}
              >
                Файлы
              </OutlineButton>
              <PrimaryButton leftIcon={<ExternalLinkIcon />} onClick={onOpen}>
                Рабочая документация
              </PrimaryButton>
            </Flex>
            <WorkingDocumentationModal onClose={onClose} isOpen={isOpen} />
            <DownloadFilesModal
              title="Файлы"
              active={isOpenFile}
              onClose={() => onCloseFile()}
              receivedFiles={files}
              fileAttach={fileAttach}
            />
          </Flex>

          <SingleContractInformationBlock />
        </Flex>

        {hasContractConstrictionRemarks() &&
                <Flex direction="column" gap={4}>
                <Flex alignItems="center" gap="6px">
                  <Title w="max-content" mb={2}>Замечания при строительстве</Title>
                  {currentContractConstructionRemarks.length > 0 && <Notice number={currentContractConstructionRemarks.length}/>}
                </Flex>
                <RemarksTable isCollapsedList={isCollapsedList} />
                <Flex justify="space-between" color="#2AB6A5">
                  <Link onClick={() => setIsCollapsedList(!isCollapsedList)}>
                    {!isCollapsedList ? "Развернуть список" : "Свернуть"}
                  </Link>
                  <Link onClick={() => navigate("/remarks/construction")}>
                    Перейти в раздел <ChevronRightIcon />
                  </Link>
                </Flex>
              </Flex>
        }


        <Flex direction="column" gap={4}>
          <Title>Связанные документы</Title>
          <Tabs
            defaultIndex={relatedDocsTabIndex}
            onChange={setDefaultTabParam(ParamKeys.RelatedDocuments)}
          >
            <ChakraTabs tabs={filteredTabs} />
            <TabPanels>
              {filteredTabs.map(tab => tab.element)}
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </MainLayout>
  );
}
