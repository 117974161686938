import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { DirectionLettersEndpoints } from "../endpoints";
import {
  ICreateDirectionLetterRequest,
  IDirectionLetterCheckingAccountsResponse,
  IDirectionLetterMaterialsResponse,
  IDirectionLettersResponse,
  IDirectionLetterSuppliersResponse,
  IPrintFormResponse,
  ISingleDirectionLetterResponse,
  IUpdateDirectionLetterRequest,
} from "./types";
import { INewSingleLimitFenceCardMaterialsResponse } from "../limitFenceCards/types";

export const getDirectionLetters =
  (): AxiosPromise<IDirectionLettersResponse> =>
    axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTERS);

export const getDirectionLettersByLimitFenceCard = (
  id: number
): AxiosPromise<IDirectionLettersResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTERS + `/${id}`);

export const getDirectionLettersByContract = (
  id: number
): AxiosPromise<IDirectionLettersResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTERS + `/${id}`);

export const getSingleDirectionLetter = (
  id: string
): AxiosPromise<ISingleDirectionLetterResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}`);

export const getDirectionLetterSuppliers = (
  id: number
): AxiosPromise<IDirectionLetterSuppliersResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/suppliers`);

export const getSupplierCheckingAccounts= (
  id: string
): AxiosPromise<IDirectionLetterCheckingAccountsResponse> =>
  axiosInstance.get(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/supplier-checking-accounts`);

export const getPrintForm = (
  id: string, params: {type: string}
): AxiosPromise<IPrintFormResponse> =>
  axiosInstance.post(DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/request-print-form`, params);

  export const createDirectionLetter = (
    params: ICreateDirectionLetterRequest
  ): AxiosPromise<undefined> =>
    axiosInstance.post(
      DirectionLettersEndpoints.DIRECTION_LETTER + "/create",
      params
    );

  export const updateDirectionLetter = (
    id: string,
    params: IUpdateDirectionLetterRequest
  ): AxiosPromise<undefined> =>
    axiosInstance.post(
      DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/update`,
      params
    );

  export const getDirrectionLetterMaterials = (
    id: string
  ): AxiosPromise<IDirectionLetterMaterialsResponse> =>
    axiosInstance.get(
      DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/materials`
    );

  export const approveDirectionLetters = (
    id: string
  ): AxiosPromise<undefined> =>
    axiosInstance.post(
      DirectionLettersEndpoints.DIRECTION_LETTER + `/${id}/approve`
    );
  