import {InformationBlock} from "../InformationBlock/InformationBlock";
import {
  InformationItem, InformationItemInputGroup,
} from "../InformationBlock/InformationItem";
import {AppDispatch, RootState, useAppSelector} from "../../store";
import {StatusCell} from "../StatusCell/StatusCell";
import {InformationItemDateInput} from "../InformationBlock/InformationItemDateInput";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {ICreateLimitFenceCardRequest} from "../../api/limitFenceCards/types";
import {addSingleFenceCard} from "../../store/slices/limitFenceCards";
import {useDispatch} from "react-redux";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, Box, Input, InputGroup, InputRightElement, Link, Text, useDisclosure} from "@chakra-ui/react";
import {CompensationActModal} from "../CompensationActModal/CompensationActModal";
import { CheckboxInput } from "../lib/input/CheckboxInput";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { InformationItemForm } from "../InformationBlock/InformationItemForm";
import { fetchSupplier, resetCurrentSupplier } from "../../store/slices/supplier";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";

interface ICreateStockCardInformationBlock {
  setDisabled: (value: boolean) => void;
  formik?: any,
  setFieldValue?: any;
}

export function CreateStockCardInformationBlock({setDisabled, formik, setFieldValue}: ICreateStockCardInformationBlock) {
  const dispatch = useDispatch<AppDispatch>();
  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  const { currentSupplier } = useAppSelector(
    (state: RootState) => state.supplier
  );

  const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [canChangeData, setCanChangeData] = useState(!currentContract);

  const [supplierError, setSupplierError] = useState<boolean>(false)



  const {period_from, period_to, contract_id, create_rp, purchase_request, supplier_guid, supplier_account, supplier_account_date_from} = formik.values;

  useEffect(() => {
    
  }, [setFieldValue, currentLimitFenceCard, currentContract]);

  useEffect(() => {
    setDisabled(period_from === "" || period_to === ""  ||(Date.parse(period_from) > Date.parse(period_to)) || (purchase_request && (!supplier_guid || supplier_guid === "" || supplier_account === "" || supplier_account_date_from === "")) || !currentContract) ;
  }, [period_from, period_to, contract_id, create_rp, setDisabled, purchase_request, supplier_guid, supplier_account, supplier_account_date_from, currentContract]);

  const handleRpCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("create_rp", e.target.checked);
  };

  const handlePurchaseCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("purchase_request", e.target.checked);
    if (e.target.checked === false) {
      dispatch(resetCurrentSupplier())
      setFieldValue("supplier_inn", "")
    };
  }

  const handleSendSupplier = () => {
    if (formik.values.supplier_inn !== "") {
      setIsSpinnerRunning(true)
      dispatch(fetchSupplier(formik.values.supplier_inn))
        .then((res:any) => {
          if (res.payload?.response?.status === 500) {
            setSupplierError(true)
          } else if (res.payload.success) {
            setSupplierError(false)
          }
        })
        .finally(() => setIsSpinnerRunning(false))
    }
  }

  useEffect(() => {
    setFieldValue("supplier_name", currentSupplier?.name)
    setFieldValue("supplier_kpp", currentSupplier?.kpp)
  }, [currentSupplier,setFieldValue])


  return (
    <>
      <InformationBlock>
        <InformationItem title="Договор" isAccordion>
          {!canChangeData ?
            (
              <Link color="#2AB6A5"
                    href={`/my-documents/contracts/${currentLimitFenceCard ? currentLimitFenceCard.contract_id : currentContract?.id}`}>
                {currentContract?.name ?? currentLimitFenceCard?.contract_name}
              </Link>
            ) : (
              <Accordion color="#5C657E" border="1px solid #E3E7EF" borderRadius="8px">
                <AccordionItem>
                  <h2>
                    <AccordionButton onClick={onOpen}>
                      <Box as="span" flex='1' textAlign='left'>
                        {currentContract?.name ?? currentLimitFenceCard?.contract_name}
                      </Box>
                      <AccordionIcon/>
                    </AccordionButton>
                  </h2>
                </AccordionItem>
              </Accordion>
            )
          }

        </InformationItem>
        <InformationItem title="Создать РП">
          <CheckboxInput
            label=""
            name="create_rp"
            value={formik.values.create_rp}
            onChange={handleRpCheckboxChange}
          />
        </InformationItem>
        <InformationItem title="Статус">
          <StatusCell value=""/>
        </InformationItem>
        <InformationItem title="Номер">{currentContract ? "" : currentLimitFenceCard?.number}</InformationItem>
        <InformationItem title="Организация">
          {currentContract?.a101_org_name ?? currentLimitFenceCard?.a101_org_name}
        </InformationItem>
        <InformationItem title="Контрагент">{currentContract?.organization.org_name ?? currentLimitFenceCard?.organization_name}</InformationItem>
        <InformationItem title="Проект">{currentContract?.project?.name ?? currentLimitFenceCard?.project_name}</InformationItem>
        <InformationItem title="ССОМ">
          {currentContract ? "" : currentLimitFenceCard?.ssom}
        </InformationItem>
        <InformationItem title="Период" tooltipKey="limit-card_create-n-view_period">
          <InformationItemInputGroup>
            <InformationItemDateInput
              id="period_from"
              name="period_from"
              value={formik.values.period_from}
              onChange={formik.handleChange}
              placeholder="С"
              isInvalid={formik.values.period_from === ''}
            />
            <InformationItemDateInput
              id="period_to"
              name="period_to"
              value={formik.values.period_to}
              onChange={formik.handleChange}
              placeholder="По"
              isInvalid={formik.values.period_to === ''}
            />
          </InformationItemInputGroup>
        </InformationItem>
        <InformationItem title="Объекты строительства">
          {currentContract?.short_description_construction_object ?? currentLimitFenceCard?.construction_objects}
        </InformationItem>
        <InformationItem title="Исполнитель">
          {currentContract?.executor ?? currentLimitFenceCard?.performer_full_name}
        </InformationItem>
        <InformationItem title="Ответственный">
          {currentContract ? "" : currentLimitFenceCard?.responsible_full_name}
        </InformationItem>
        <InformationItem title="Создать заявку на закупку">
          <CheckboxInput
            label=""
            name="purchase_request"
            value={formik.values.purchase_request}
            onChange={handlePurchaseCheckboxChange}
          />
        </InformationItem>
        {formik.values.purchase_request ? (
          <>
            <InformationItem title="ИНН поставщика" isAccordion>
              <InputGroup h="36px" flex="1">
                <Input
                  name="supplier_inn"
                  id="supplier_inn"
                  h="36px"
                  rounded="md"
                  borderColor="#E3E7EF"
                  placeholder="Введите значение"
                  position="absolute"
                  top="50%"
                  transform="translate(0, -50%)"
                  value={formik.values.supplier_inn}
                  onChange={formik.handleChange}
                />
                <InputRightElement height="36px" width='30px'>
                  <ArrowForwardIcon onClick={handleSendSupplier} color="grey" w="24px" h="24px" sx={{
                    "&:hover": {
                      opacity: 0.6,
                      cursor: "pointer"
                    }
                  }} />
                </InputRightElement>
              </InputGroup>
              {supplierError ? <Text fontSize="14" color="#E8363D" marginTop="8px">Компания не найдена</Text> : (<></>)}
            </InformationItem>
            <InformationItem title="Название поставщика">
              {currentSupplier?.name}
            </InformationItem>
            <InformationItem title="КПП поставщика">
              {currentSupplier?.kpp}
            </InformationItem>
            <InformationItem title="Счет поставщика">
              <InformationItemForm
                firstId="supplier_account"
                secondId="supplier_account_date_from"
                firstValue={formik.values.supplier_account}
                secondValue={formik.values.supplier_account_date_from}
                onChange={formik.handleChange}
                required={true}
              />
            </InformationItem>


          </>
        ) : (<></>)}
      </InformationBlock>
      <FullScreenWaitingModal openModal={isSpinnerRunning} />
      <CompensationActModal isAok={false} isInsideCreate={true} isOpen={isOpen} onClose={onClose} />
    </>
  );
}
