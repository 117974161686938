import { SearchIcon } from "@chakra-ui/icons";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { MainTable } from "./MainTable";
import {useAppDispatch, useAppSelector} from "../../../store";
import {useEffect, useState} from "react";
import { formatAmount } from "../../../helpers/formatAmount";
import { estimateDetailsWithImport } from "../../../helpers/estimateDetailWithImport";
import { changeEstimate } from "../../../store/slices/estimates";

export function MainTableBlock() {
  const { currentDetails, currentImportEstimate, currentEstimate } = useAppSelector((state) => state.estimates);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debounceSearchValue, setDebounceSearchValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | string | number | undefined>();
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentImportEstimate.length > 0) {
      const resultDetails = estimateDetailsWithImport(currentEstimate?.details, currentImportEstimate)
      const updatedEstimate = { ...currentEstimate, details: resultDetails };
      dispatch(changeEstimate(updatedEstimate))
    }
  }, [currentImportEstimate])


  useEffect(() => {
    const calculateTotal = (details:any) => {
      let total = 0;
      details.forEach((detail:any) => {
        let quantity = 0;
        if (Number(detail.quantityinthedocument) === 0 && Number(detail.Thenumberofstops) !== 0) {
          quantity = Number(detail.Thenumberofstops);
        } else {
          quantity = Number(detail.quantityinthedocument);
        }
        const price = Number(detail.Price) || 0;
        total += quantity * price;
  
        if (detail.subRows && detail.subRows.length > 0) {
          total += calculateTotal(detail.subRows);
        }
      });
      return total;
    };
    const totalSum = calculateTotal(currentDetails);
    const fixedTotalSum = totalSum.toFixed(2);
    setTotal(Number(fixedTotalSum));
  }, [currentDetails]);

  useEffect(() => {
    if (debounceTimer) clearTimeout(debounceTimer);

    const newTimer = setTimeout(() => {
      if(debounceSearchValue !== searchValue){setDebounceSearchValue(searchValue)};
    }, 100);

    setDebounceTimer(newTimer);
    return () => clearTimeout(newTimer);
  }, [searchValue]);

  return (
    <Flex direction="column" gap={2}>
      <Flex w="full" align="center" justify="space-between" >
        <InputGroup bg="white" borderColor="#E3E7EF" maxW="320px">
          <InputLeftElement>
            <SearchIcon color="#838A9D" />
          </InputLeftElement>
          <Input placeholder="Поиск" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
        </InputGroup>
        <Text fontWeight={500}>ИТОГО: {formatAmount(total)}</Text>
      </Flex>
      <MainTable searchValue={debounceSearchValue}/>
    </Flex>
  );
}
