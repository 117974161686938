import { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  ColumnDef,
  CellContext,
} from "@tanstack/react-table";
import { Text } from "@chakra-ui/react";
import { Table } from "../lib/table/Table";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IWorkCompletedActDetail } from "../../api/workCompletedActs/types";
import { setWorkCompletedActDetails } from "../../store/slices/workCompletedActs";
import { IDetail } from "../../api/estimates/types";
import { formatAmount } from "../../helpers/formatAmount";
import { formatWorkActNumbers } from "../../helpers/formatWorkActNumbers";

const columnHelper = createColumnHelper<IWorkCompletedActDetail>();

const WorkTypeCell = (info: CellContext<IWorkCompletedActDetail, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

export function BuildingObjectsTable() {
  const dispatch = useAppDispatch();

  const { currentWorkCompletedAct } = useAppSelector((state: RootState) => state.workCompletedActs);
  const { currentEstimate } = useAppSelector((state: RootState) => state.estimates);

  const findMatchingItem = (actDetail: IWorkCompletedActDetail, estimateDetails: IDetail[] | undefined): IDetail | undefined => {
    if (!estimateDetails) return undefined;
  
    for (const detail of estimateDetails) {
      if (
        actDetail.construction_object_guid === detail.ConstructionObjectID &&
        actDetail.type_of_work_guid === detail.TypeofWorkID
      ) {
        return detail;
      }
      if (detail.subRows) {
        const found = findMatchingItem(actDetail, detail.subRows);
        if (found) return found;
      }
    }
    return undefined;
  };


  const resultConstructionObjects = useMemo(() => {
    return ((actDetails: IWorkCompletedActDetail[] | undefined, estimateDetails: IDetail[] | undefined) =>
      actDetails?.map((actDetail:IWorkCompletedActDetail) => {
        const matchingItem = findMatchingItem(actDetail, estimateDetails)

        return matchingItem
          ? {
            ...actDetail,
            construction_object_name: matchingItem.ConstructionObjectName,
            type_of_work_name: matchingItem.TypeofWorkName
          }
          : actDetail;
      })
    )(currentWorkCompletedAct?.details, currentEstimate?.details);
  }, [currentWorkCompletedAct?.details, currentEstimate?.details]);


  useEffect(() => {
    resultConstructionObjects && dispatch(setWorkCompletedActDetails(resultConstructionObjects));
  }, [dispatch, resultConstructionObjects]); 

  const columns = useMemo<Array<ColumnDef<IWorkCompletedActDetail, string>>>(
    () => [
      columnHelper.accessor("construction_object_name", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("type_of_work_name", {
        header: "Вид работы",
        cell: WorkTypeCell,
      }),
      columnHelper.accessor("quantity", {
        header: "Количество",
        cell: (info) => formatWorkActNumbers(info.getValue()),
      }),
      columnHelper.accessor("price", {
        header: "Цена",
        cell: (info) => formatAmount(info.renderValue())
      }),
      columnHelper.accessor("total", {
        header: "Сумма",
        cell: (info) => formatAmount(info.renderValue())
      }),
    ],
    []
  );

  return <Table data={resultConstructionObjects ?? []} columns={columns} />;
}
